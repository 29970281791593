import { store } from "@/store/store";
import { eventBus } from "@/eventBus";
import { mapMutations } from "vuex";
import Utility from "@/mixins/Utility";

const Redirection = {
  methods: {
    ...mapMutations(["setFirebaseUserUID", "setRegActiveDeeplink", "setContentData"]),

    redirectToLocator(content) {
      if(this.locatorData(content)) {
        window.open(this.locatorData(content), '_blank', 'noopener,noreferrer');
      }else {
        alert('sorry..! content is not accessible.')
      }
    }, 

    locatorData(content) {
      let locaterData = content?.contentdetails?.filter((item, i) =>{
        return item.devicetype === "web"
      });
      let locater = locaterData && locaterData[0]?.locator;
      if(locater && content?.contentprovider == "OTT_PLAY") {
          let token = localStorage.getItem("sessionToken");
          let source = "KCCL";
          let backUrl = encodeURIComponent(window.location.href);
          locater = `https://www.ottplay.com/auth?seoUrl=${locater}&token=${token}&backUrl=${backUrl}&source=${source}`;
      }
      return locater;
    },

    redirectToLogin() {
        let payload = {
            state: true,
            formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
    },

    redirectToPlans() {
    this.setRegActiveDeeplink(true);
    localStorage.setItem("paymentGetwaySaveUrl", location.href);
    let currentLanguage = this.getCurrentLanguageUrlBase();
    this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
    },

    redirectToDetailPage(item) {
      this.setContentData(item);
      console.log('redirectToDetailPage', item)
      let category = 'movies';
      if (item?.category?.toLowerCase() == 'tvshow') {
          category = 'tvshows';
      } else if (item?.category?.toLowerCase() == 'movie') {
          category ='movies';
      } else if (item?.category?.toLowerCase() == 'tvchanel') {
          category = 'livetv';
      } else if (item?.objecttype?.toLowerCase() == 'season') {
          category = 'tvshows';
      } 
      this.$router.push({
          name: 'detailPageEarth', params: {
          category: category,
          title: this.cleanString(item.title),
          contentId: item.objecttype == "SEASON" ? item.seriesid : item.objectid
          }
      });
    },
    
    // getCurrentLanguageUrlBase: function() {
    // let lang = localStorage.getItem("setDisplayLanguageCode");
    // let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
    // let supportedLanguages = window.PROVIDER_SETUP_CONFIG.supportLanguageList;

    // if (lang === defaultLanguage) {
    //     return null;
    // }
    // for(let index = 0; index < supportedLanguages.length; index ++){
    //     let languageItem = supportedLanguages[index];
    //     if(lang === languageItem){
    //     return languageItem;
    //     }
    // }
    // return 'en';
    // },

    redirectToSeeAll(screen) {
      let screenData = JSON.stringify(screen);
      localStorage.setItem("screen-list-collection", screenData);

      let currentLanguage = this.getCurrentLanguageUrlBase();


      let titleDefault = this.spacialCharEncoding(screen.title.default);
      this.$router.push({
        name: "collections",
        params: { section: titleDefault.toLowerCase(), slug: this.screenId, lang: currentLanguage },
      });

    },

    cleanString(input) {
      return input
          .toLowerCase() // Convert to lowercase for consistency
          .replace(/[^a-z0-9\s-]/g, '-') // Replace special characters with hyphens
          .replace(/\s+/g, '-') // Replace spaces with a single hyphen
          .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
          .replace(/^-|-$/g, ''); // Remove leading or trailing hyphens
    }
  },
  mixins: [Utility],
};

export default Redirection;